import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import '../mapstyles.css';
import {Marker} from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import cometumuc from '../../images/cometomuc.png';



mapboxgl.accessToken =
    'pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja24zZmhxODAwOGpoMnZvMGpnZmtrNnhiIn0.62IOjLEwae3VzgbXme8MNg';
    // mine is pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja3AyanpkZGYwNmNjMnVta25scmhob3MxIn0.DUIm9sqf6hql4AYqIeSqBg
    // mine default
    // default is pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA

const Map = () => {
    const mapContainerRef = useRef(null);

    const [lng, setLng] = useState(16.0);
    const [lat, setLat] = useState(46.0);
    const [zoom, setZoom] = useState(4.5);

    // Initialize map when component mounts
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
            pitch: 0,
            accessToken: 'pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja24zZmhxODAwOGpoMnZvMGpnZmtrNnhiIn0.62IOjLEwae3VzgbXme8MNg'
        });

        const chapters = {
            'empty':{
                bearing: 0,
                center: [lng, lat],
                zoom: 1.5,
                pitch: 0

            },
            'TarelkaTechInstitute': {
                bearing: 0,
                center: [30.3066193, 59.9155319],
                zoom: 17,
                pitch: 12
            },
            'SokosOlympiaGarden': {
                bearing: 90,
                center: [30.320638, 59.913541],
                zoom: 16,
                pitch: 10,
                speed: 0.5
            },
            'CornerBakerei': {
                bearing: 0,
                center: [11.5526263, 48.1805516],
                zoom: 13,
                speed: 0.6,
                pitch: 20
            },
            'VictoryCafe': {
                bearing: 90,
                center: [9.2145183, 45.4683735],
                zoom: 16
            },
            'Pasticceria': {
                bearing: 180,
                center: [9.2298886, 45.8109103],
                zoom: 16
        },
            'Simple Coffee': {
                bearing: 180,
                center: [lng, lat],
                zoom: 3,
                pitch: 50
            }
        };


        let activeChapterName = 'empty';

        function setActiveChapter(chapterName) {
            if (chapterName === activeChapterName) return;

            map.flyTo(chapters[chapterName]);

            document.getElementById(chapterName).classList.add('active');
            document.getElementById(activeChapterName).classList.remove('active');

            activeChapterName = chapterName;
        }

        function isElementOnScreen(id) {
            const element = document.getElementById(id);
            const bounds = element.getBoundingClientRect();
            return bounds.top < window.innerHeight && bounds.bottom > 0;
        }

        // On every scroll event, check which element is on screen
        window.onscroll = () => {
            for (const chapterName in chapters) {
                if (isElementOnScreen(chapterName)) {
                    setActiveChapter(chapterName);
                    break;
                }
            }
        };

        map.on('load', () => {

            map.addSource('places', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'properties': {
                                    'description':
                                        '<strong>Make it Mount Pleasant</strong><p>Make it Mount Pleasant is a handmade and ' +
                                        'vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>'
                                },
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [9.227965, 45.815042]
                                }
                            },
                            {
                                'type': 'Feature',
                                'properties': {
                                    'description':
                                        '<strong>Make it Mount Pleasant</strong><p>Make it Mount Pleasant is a handmade and ' +
                                        'vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>'
                                },
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [9.165451, 45.459905]
                                }
                            },
                            {
                                'type': 'Feature',
                                'properties': {
                                    'description':
                                        '<strong>Make it Mount Pleasant</strong><p>Make it Mount Pleasant is a handmade and ' +
                                        'vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>'
                                },
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [11.553527, 48.179592]
                                }
                            }
                        ]
                    }
                },
                map.addSource('mapbox-dem', {
                    'type': 'raster-dem',
                    'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                    'tileSize': 512,
                    'maxzoom': 14
                }),
                map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 }),

                map.addLayer({
                    'id': 'sky',
                    'type': 'sky',
                    'paint': {
// set up the sky layer to use a color gradient
                        'sky-type': 'gradient',
// the sky will be lightest in the center and get darker moving radially outward
// this simulates the look of the sun just below the horizon
                        'sky-gradient': [
                            'interpolate',
                            ['linear'],
                            ['sky-radial-progress'],
                            0.8,
                            'rgba(135, 206, 235, 1.0)',
                            1,
                            'rgba(0,0,0,0.1)'
                        ],
                        'sky-gradient-center': [0, 0],
                        'sky-gradient-radius': 90,
                        'sky-opacity': [
                            'interpolate',
                            ['exponential', 0.1],
                            ['zoom'],
                            5,
                            0,
                            22,
                            1
                        ]
                    }
                })


            );
// Add a layer showing the places.
            map.addLayer({
                'id': 'places',
                'type': 'circle',
                'source': 'places',
                'paint': {
                    'circle-color': '#4264fb',
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff'
                }
            });

// Create a popup, but don't add it to the map yet.
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            map.on('mouseenter', 'places', (e) => {
// Change the cursor style as a UI indicator.
                map.getCanvas().style.cursor = 'pointer';

// Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();
                const description = e.features[0].properties.description;

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

// Populate the popup and set its coordinates
// based on the feature found.
                popup.setLngLat(coordinates).setHTML(description).addTo(map);
            });

            map.on('mouseleave', 'places', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
        });







        /////////////////////////

        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

//
// // create DOM element for the marker
//         var el = document.createElement('div');
//         el.id = 'marker';

// // create the marker
//         new mapboxgl.Marker(el)
//             .setLngLat([12.12567, 46.575])
//             .setPopup(popup) // sets a popup on this marker
//             .addTo(map);



        // Clean up on unmount
        return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className='map-container' ref={mapContainerRef} id='map'> </div>
            <div id="features">

                <h1 style={{marginTop: `100px`, marginLeft: `auto`, marginRight:`auto`, textAlign: `auto`, color: `black`}}>
                    Checked Places
                </h1>

                <p style={{marginLeft: `auto`, marginRight:`auto`, textAlign: `auto`,color: `black`}}>
                    Certainly comfortable and cool places I visit often and where I used to spend some time.
                </p>


                <section id="empty">
                    <h3></h3>
                    <p>
                        <img src="https://img.icons8.com/ios/452/inspection.png"/>
                    </p>
                </section>

                <section id="TarelkaTechInstitute" className="active">
                    <h3>Tarelka Tech Institute</h3>
                    <br />
                    <p>
                        Actually a network of Cafes. Saved my life almost everyday while staying in the St.Petersburg.
                        Take Lunch and Latte, cool bakery. Some students usually are on place. Mostly architects with insanely huge sketches.
                        Plenty of sockets, great wifi. Used to work here literally daily.
                    </p>
                </section>

                <section id="SokosOlympiaGarden" >
                    <h3>Sokos Olympia Garden</h3>
                    <br />
                    <p>
                        The best place to be in St. Petersburg. Used to stay here when first time came to the city in 2012.
                        Then spent almost 3 years around these places.
                        <br />
                        Book a night here if you go to the north, ask a room with a view to Klinsky Prospekt.
                    </p>
                </section>

                <section id="CornerBakerei">
                    <h3>Ratschiller's Bäckerei und Café</h3>
                    <p>
                        Capuccino, Schokokrapfen und Eierbreze. Täglich. 1 Jahr.
                    </p>
                </section>

                <section id="VictoryCafe">
                    <h3>Victory Cafe</h3>
                    <p>
                       One of the good places here. Take a seat outside on plane alley and with a view on Corso Indipendenza.
                        <br />
                        Consider that there is -1 floor, its area is x3 times bigger than the ground floor.
                        Somehow, you have to ask Sr.Matteo to access it.
                        <br />
                        The best place to mark some little victories in this city heh.
                    </p>
                </section>

                <section id="Pasticceria">
                    <h3>Vago Erba Pasticceria</h3>
                    <p>
                        Simply the best place to live nearby. 10/10 on a vibe scale.
                    </p>
                </section>

                <section id="SimpleCoffee">
                    <h3>()</h3>
                    {/*<p>*/}
                    {/*    There are some <a href="/where-to-go/">destinations</a> I hope to reach soon*/}
                    {/*    <br />*/}
                    {/*    <br />*/}
                    {/*    <svg viewBox={"0 0 50 50"}>*/}
                    {/*        /!*<polygon fill="#6B6570" points="70,70 50,50 50,70" />*!/*/}
                    {/*        <rect x={"0"} y={"0"}*/}
                    {/*              width={"100%"} height={"50%"}*/}
                    {/*              fill={"black"}*/}
                    {/*        />*/}
                    {/*    </svg>*/}

                    {/*</p>*/}
                    <small id="citation">
                        Created with care just for fun
                        {/*Adapted from <a href="http://www.gutenberg.org/files/2346/2346-h/2346-h.htm">Project Gutenberg</a>*/}
                    </small>
                </section>
            </div>
        </div>
    );
};

export default Map;